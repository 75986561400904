/* eslint-disable no-underscore-dangle */
import React from 'react'

// Lazy loading
import loadable from '@loadable/component'

interface FlexContentProps {
  prefix?: string
  fields: any
  location?: any
}

interface FlexerProps {
  [key: string]: any
}

interface ComponentProps {
  fields: any
  location?: any
}

const FlexContentHandler: React.FC<FlexContentProps> = ({
  prefix = 'page_Flexcontent',
  fields,
  location = {},
}) => {
  if (!fields) {
    return null
  }

  const flexers: FlexerProps = {
    [`${prefix}_Flex_Banner`]: loadable(
      () => import('components/flex/Banner/BannerShell')
    ),
    [`${prefix}_Flex_BannerCta`]: loadable(
      () => import('components/flex/Banner/BannerCta')
    ),
    [`${prefix}_Flex_Form`]: loadable(() => import('components/flex/Form')),
    [`${prefix}_Flex_Text`]: loadable(
      () => import('components/flex/Text/TextShell')
    ),
    [`${prefix}_Flex_TextWithBlocks`]: loadable(
      () => import('components/flex/Text/TextWithBlocks')
    ),
    [`${prefix}_Flex_TextFullWidth`]: loadable(
      () => import('components/flex/Text/TextFullWidth')
    ),
    [`${prefix}_Flex_Gallery`]: loadable(
      () => import('components/flex/Gallery')
    ),
    [`${prefix}_Flex_Usps`]: loadable(() => import('components/flex/Usps')),
    [`${prefix}_Flex_LatestVacatures`]: loadable(
      () => import('components/flex/LatestVacatures')
    ),
    [`${prefix}_Flex_HighlightedProject`]: loadable(
      () => import('components/flex/HighlightedProject')
    ),
    [`${prefix}_Flex_HighlightedVacancies`]: loadable(
      () => import('components/flex/HighlightedVacancies')
    ),
    [`${prefix}_Flex_Team`]: loadable(() => import('components/flex/Team')),
    [`${prefix}_Flex_GoogleMaps`]: loadable(
      () => import('components/flex/GoogleMaps')
    ),
    [`${prefix}_Flex_Blog`]: loadable(
      () => import('components/flex/Blog/BlogShell')
    ),
    [`${prefix}_Flex_Accordeon`]: loadable(
      () => import('components/flex/Accordeon')
    ),
    [`${prefix}_Flex_Calendar`]: loadable(
      () => import('components/flex/Calendar')
    ),
    [`${prefix}_Flex_Colleagues`]: loadable(
      () => import('components/flex/Colleagues')
    ),
    [`${prefix}_Flex_CallToAction`]: loadable(
      () => import('components/flex/CallToAction')
    ),
    [`${prefix}_Flex_Fragment`]: loadable(
      () => import('components/flex/Fragment')
    ),
  }

  if (!fields.flex) {
    return null
  }

  return (
    <div>
      {fields.flex.map((section: any, index: number) => {
        if (!section.fieldGroupName || !flexers[section.fieldGroupName]) {
          return null
        }

        const Component: React.FC<ComponentProps> =
          flexers[section.fieldGroupName]

        return (
          <Component
            // eslint-disable-next-line react/no-array-index-key
            key={`${location ? location.pathname : 'flex'}-${index}`}
            fields={section}
            location={location}
          />
        )
      })}
    </div>
  )
}

export default FlexContentHandler
