const mainTheme = {
  color: {
    primary: '#1D3660',
    secondary: '#FF8300',
    grey: '#D1D4DD',
    alternate: '#676767',
    dark: '#4F4F4F',
    light: '#FFFFFF',
    background: '#F8F8F8',
    border: '#DDDDDD',
    gradient: {
      contrast: {
        from: '241,37,48',
        to: '255,132,0',
      },
      dark: {
        from: '15,15,15',
        to: '88,88,88',
      },
    },
  },

  font: {
    family: {
      main: 'Montserrat, sans-serif',
      secondary: 'co-headline, sans-serif',
    },

    size: {
      tiny: '10px',
      12: '12px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      subheading: '50px',
      giga: '65px',
      unit: '80px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
