import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

// CSS
import 'styles/all.css'

const ContentTypeStyles: any = {
  'transparent-orange': css``,
  'transparent-black': css``,
  'solid-orange': css`
    padding-top: 140px !important;

    @media screen and (max-width: 991px) {
      padding-top: 95px !important;
    }
  `,
  'solid-black': css`
    padding-top: 140px !important;

    @media screen and (max-width: 991px) {
      padding-top: 95px !important;
    }
  `,
}

const Content = styled.div<{ isSticky?: boolean; thema?: any }>`
  @media (min-width: 992px) {
    padding-top: 45px;
  }
  background-color: ${(props) => props.theme.color.background};
  ${({ thema }) => ContentTypeStyles[thema]}
`

interface LayoutProps {
  children: any
  theme?: string
}

const Layout: React.FC<LayoutProps> = ({
  children,
  theme = 'transparent-orange',
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider value={{ setIsScrollable }}>
        <Header
          theme={theme}
          isSticky={isSticky}
          setIsScrollable={setIsScrollable}
        />
        <Content thema={theme || 'transparent-orange'} isSticky={isSticky}>
          {children}
        </Content>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export const LayoutContext = React.createContext({
  setIsScrollable: (bool: boolean) => bool,
})

export default Layout
