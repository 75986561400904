/* eslint-disable import/no-unresolved */
import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { useMedia } from 'react-use'

// Images
import Logo from 'img/logo.inline.svg'
import Fingerprint from 'src/img/fingerprint.inline.svg'
import chevronWhite from 'img/chevron-down.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'
import ButtonDefaultArrow from 'components/elements/Buttons/ButtonDefaultArrow'

const StyledHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 140px;
  z-index: 99;

  @media screen and (max-width: 991px) {
    height: 95px;
  }
`

const Brand = styled(CustomLink)`
  font-size: 0;
  & > svg {
    width: 200px;
    @media (min-width: 992px) {
      height: 45px;
    }

    @media (max-width: 991px) {
      height: 35px;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 99;
  font-size: 0;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 4px;
  width: 55%;
  margin: 5px auto;
  border-radius: 2px;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  type?: string
  theme?: string
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky,
  type,
  theme,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          topMenuHeader {
            description
          }
          menuHeader {
            link {
              title
              url
            }
            menuMenu {
              link {
                title
                url
              }
            }
          }
        }
      }

      allWpVacature(
        filter: {
          vacaturedetail: { showvacature: { eq: true } }
          databaseId: { ne: 1300 }
        }
      ) {
        totalCount
      }
    }
  `)

  let headertype = type

  if (isSticky) {
    headertype = 'onPage'
  }

  if (!isSticky) {
    headertype = 'onTop'
  }

  return (
    <StyledHeader>
      <MenuMobile
        thema={theme || 'solid-orange'}
        fields={query?.fields}
        setIsScrollable={setIsScrollable}
        type={headertype}
        count={query?.allWpVacature.totalCount}
      />
      <MenuDesktop
        thema={theme || 'solid-orange'}
        fields={query?.fields}
        isSticky={isSticky}
        type={headertype}
        count={query?.allWpVacature.totalCount}
      />
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.background};
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 70%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 30px;
        font-weight: ${({ theme }) => theme.font.weight.bold};
        display: block;
        padding: 0px 0;
        color: ${(props) => props.theme.color.secondary} !important;
      }

      &:last-child a {
        border-bottom: none;
      }
    }

    @media (max-width: 991px) {
      text-align: left;
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 25px;
  top: 2px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.dark};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 5px;
    border-radius: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuMobileWrapper = styled.div<{ thema: string; type: string }>`
  ${({ thema, theme, type }) =>
    thema.includes('transparent')
      ? css`
          ${type === 'onPage' &&
        css`
            background: ${theme.color.gradient.contrast.to};
            background: ${`rgb(${theme.color.gradient.to})`};
            background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 35%);`};
            box-shadow: 0 0 14px rgba(0, 0, 0, 0.35);
          `}
        `
      : css`
          background: ${theme.color.gradient.contrast.to};
          background: ${`rgb(${theme.color.gradient.to})`};
          background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 35%);`};
          box-shadow: 0 0 14px rgba(0, 0, 0, 0.35);
        `}
  height: 95px;
`

// @ts-ignore
const MenuMobileFingerprint = styled(Fingerprint)`
  position: absolute;
  z-index: 1;

  @media (min-width: 992px) {
    width: 900px;
    top: -150px;
    left: 50%;
  }

  @media (max-width: 991px) {
    width: 700px;
    top: -150px;
    left: 45%;
  }

  @media (max-width: 767px) {
    width: 500px;
    top: -250px;
    left: 30%;
  }
`

const MenuMobileContent = styled.div`
  position: relative;
  z-index: 2;
  overflow: scroll;
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
  type?: string
  count: number
  thema: string
}

const MenuMobile: React.FC<MenuMobileProps> = ({
  fields,
  setIsScrollable,
  type,
  count,
  thema,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <MenuMobileWrapper
      thema={thema}
      className="d-flex d-lg-none justify-content-between align-items-center"
      type={type || 'onPage'}
    >
      <Brand to="/">
        <Logo />
        Home
      </Brand>
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        Menu
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <MenuMobileFingerprint />
          <MenuMobileContent className="pt-5">
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu count={count} fields={fields} />
              <TopMenu fields={fields} />
            </ul>
          </MenuMobileContent>
        </MenuWrapper>
      )}
    </MenuMobileWrapper>
  )
}

const TopMenuWrapper = styled.div`
  height: 45px;
  background-color: ${(props) => props.theme.color.light};
  & a:hover {
    opacity: 0.75;
  }
`

const MainMenuWrapper = styled(motion.div)`
  ${({ thema, theme, type }) =>
    thema.includes('transparent')
      ? css`
          ${type === 'onPage' &&
        css`
            background: ${theme.color.gradient.contrast.to};
            background: ${`rgb(${theme.color.gradient.to})`};
            background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 35%);`};
            box-shadow: 1px 6px 21px -2px rgba(0, 0, 0, 0.31);
          `}
        `
      : css`
          background: ${theme.color.gradient.contrast.to};
          background: ${`rgb(${theme.color.gradient.to})`};
          background: ${`linear-gradient(90deg, rgba(${theme.color.gradient.contrast.from},1) 0%, rgba(${theme.color.gradient.contrast.to},1) 35%);`};
          box-shadow: 1px 6px 21px -2px rgba(0, 0, 0, 0.31);
        `}

  height: 95px;
`

const StyledMenuDesktop = styled.ul`
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
  type?: string
  thema?: string
  count?: number
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({
  count,
  fields,
  type,
  thema,
}) => (
  <div className="d-none d-lg-block">
    <TopMenuWrapper>
      <StyledMenuDesktop className="h-100 container d-flex justify-content-end">
        <TopMenu fields={fields} />
      </StyledMenuDesktop>
    </TopMenuWrapper>
    <MainMenuWrapper thema={thema} type={type}>
      <StyledMenuDesktop className="h-100 container d-flex">
        <Brand to="/">
          <Logo />
        </Brand>
        <Menu count={count || 0} fields={fields} />
      </StyledMenuDesktop>
    </MainMenuWrapper>
  </div>
)

const Item = styled.li`
  position: relative;

  & > a {
    color: ${(props) => props.theme.color.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.regular};
      &:before {
        visibility: visible;
        width: 100%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: -5px;
      left: 0;
      background-color: ${({ theme }) => theme.color.light};
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:hover:before {
      visibility: visible;
      width: 100%;
    }

    &:hover {
      color: ${({ theme }) => theme.color.light};
    }
  }

  @media (max-width: 991px) {
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 5px 15px 10px 15px !important;
  }

  @media (max-width: 991px) {
    display: flex;
  }
`

const TopMenuContent = styled(ParseContent)`
  & img {
    margin: 0 5px;
    display: inline-block;
    width: 22px;
  }

  & .social-icons {
    @media (max-width: 991px) {
      display: flex !important;
      margin-top: 3rem;
      justify-content: center;
      & img {
        width: 40px !important;
      }
    }
  }

  & a {
    text-decoration: none;
    width: 100%;
    font-size: ${(props) => props.theme.font.size.small} !important;
    &:hover {
      color: ${(props) => props.theme.color.secondary};
    }
  }

  & p {
    margin: 0;
  }
`

interface TopMenuProps {
  fields: any
}

const TopMenu: React.FC<TopMenuProps> = ({ fields }) => (
  <li className="mt-5 mt-lg-0">
    <TopMenuContent content={fields?.header?.topMenuHeader?.description} />
  </li>
)

interface MenuProps {
  fields: any
  count: number
}

const Amount = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
  }
  @media (max-width: 991px) {
    margin-left: 1rem;
  }

  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.light};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 12px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    right: 0;
  }
`

const Submenu = styled(motion.div)`
  padding-top: 10px;
  position: absolute;
  min-width: 594px;
  left: -165px;

  @media (min-width: 1399px) {
    left: -35px;
  }
`

const SubmenuContainer = styled.div`
  background-color: ${(props) => props.theme.color.light};
  padding: 35px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  z-index: 5;
  min-height: 194px;

  ${Item} {
    padding: 3px 0px 3px 0px;
  }
`

const ItemDiv = styled.div`
  padding: 4px 0 4px 0;
  color: ${(props) => props.theme.color.secondary};
  font-size: ${(props) => props.theme.font.size.small};

  & > a {
    &[aria-current] {
    }

    &:hover {
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: left;
    color: ${(props) => props.theme.color.light};
    font-size: ${(props) => props.theme.font.size.mega};
    padding: 10px 0;

    & > a {
      border-bottom: none;
    }
  }
`

const LinkSub = styled.a`
  color: ${(props) => props.theme.color.light} !important;
  font-family: ${(props) => props.theme.font.family.secondary};
  font-weight: 300;

  > a {
    color: ${(props) => props.theme.color.light} !important;
    font-family: ${(props) => props.theme.font.family.secondary};
    font-weight: 300;
  }

  &:hover {
    color: ${(props) => props.theme.color.light} !important;
  }

  & svg {
    margin-left: 6px;
    margin-top: 5px;

    & path {
      fill: #fff;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }
`

const MobileItemDiv = styled.div`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.light};
  font-size: ${(props) => props.theme.font.size['18']};
  font-weight: ${(props) => props.theme.font.weight.regular};
  padding: 2px 0 2px 20px;

  & > a {
    border-bottom: none;
  }
`

const Menu: React.FC<MenuProps> = ({ count, fields }) => {
  const isMobile = useMedia('(max-width: 991px)')

  return (
    <>
      {fields.header.menuHeader.map(
        ({ link: { url, title }, menuMenu }: any) => {
          if (menuMenu && menuMenu.length > 0) {
            const [open, setOpen] = useState(false)
            return (
              <Fragment key={url}>
                <Item>
                  <motion.div
                    onHoverStart={() => setOpen(true)}
                    onHoverEnd={() => setOpen(false)}
                    animate={open ? 'hover' : 'hidden'}
                    className="position-relative"
                  >
                    <LinkSub
                      onClick={() => setOpen(!open)}
                      className="d-flex align-items-center"
                    >
                      <CustomLink to={url}>{title}</CustomLink>
                      <svg
                        className="chevron"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width="12"
                        height="12"
                        viewBox="0 0 444.819 444.819"
                        xmlSpace="preserve"
                      >
                        <g>
                          <g xmlns="http://www.w3.org/2000/svg">
                            <path d="M434.252,114.203l-21.409-21.416c-7.419-7.04-16.084-10.561-25.975-10.561c-10.095,0-18.657,3.521-25.7,10.561   L222.41,231.549L83.653,92.791c-7.042-7.04-15.606-10.561-25.697-10.561c-9.896,0-18.559,3.521-25.979,10.561l-21.128,21.416   C3.615,121.436,0,130.099,0,140.188c0,10.277,3.619,18.842,10.848,25.693l185.864,185.865c6.855,7.23,15.416,10.848,25.697,10.848   c10.088,0,18.75-3.617,25.977-10.848l185.865-185.865c7.043-7.044,10.567-15.608,10.567-25.693   C444.819,130.287,441.295,121.629,434.252,114.203z" />
                          </g>
                        </g>
                      </svg>
                      <motion.img
                        src={chevronWhite}
                        className="d-lg-none"
                        alt=""
                        animate={
                          open
                            ? { transform: 'rotate(180deg)' }
                            : { transform: 'rotate(0deg)' }
                        }
                      />
                    </LinkSub>
                    <AnimatePresence>
                      {open && isMobile && (
                        <motion.div
                          className="pt-2"
                          initial={{ height: '0px', opacity: 0 }}
                          animate={{
                            height: 'auto',
                            opacity: 1,
                            transition: {
                              type: 'spring',
                              damping: 20,
                              delay: 0.15,
                            },
                          }}
                          exit={{
                            height: '0px',
                            opacity: 0,
                            transition: { duration: 0.3 },
                          }}
                        >
                          {menuMenu.map((b) => (
                            <MobileItemDiv>
                              <CustomLink to={b.link.url}>
                                <ParseContent content={b.link.title} />
                              </CustomLink>
                            </MobileItemDiv>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    {!isMobile && (
                      <Submenu
                        initial={{
                          height: '0',
                          pointerEvents: 'none',
                          opacity: 0,
                        }}
                        animate={
                          !open
                            ? {
                              height: '0x',
                              pointerEvents: 'none',
                              opacity: 0,
                            }
                            : {
                              height: '215px',
                              pointerEvents: 'all',
                              opacity: 1,
                            }
                        }
                      >
                        <SubmenuContainer className="position-relative d-flex">
                          <motion.div
                            variants={{
                              hidden: { opacity: 0, x: 20 },
                              hover: { opacity: 1, x: 0 },
                            }}
                            className="col-lg-6"
                          >
                            {menuMenu.map((b) => {
                              const controls = useAnimation()
                              const onHoverEnd = () => {
                                controls.start('subHidden')
                              }
                              const onHoverStart = () => {
                                controls.start('subHover')
                              }
                              return (
                                <ItemDiv>
                                  <motion.div
                                    onHoverStart={onHoverStart}
                                    onHoverEnd={onHoverEnd}
                                  >
                                    <CustomLink to={b.link.url}>
                                      <ParseContent content={b.link.title} />
                                    </CustomLink>
                                  </motion.div>
                                </ItemDiv>
                              )
                            })}
                          </motion.div>
                          <motion.div
                            variants={{
                              hidden: { opacity: 0, x: 20 },
                              hover: { opacity: 1, x: 0 },
                            }}
                            className="col-lg-6"
                          >
                            <p className="font-family-secondary font-size-big">
                              Op de hoogte blijven?
                            </p>
                            <ButtonDefaultArrow to="/vacatures">
                              Stel vacature alert in
                            </ButtonDefaultArrow>
                          </motion.div>
                        </SubmenuContainer>
                      </Submenu>
                    )}
                  </motion.div>
                </Item>
              </Fragment>
            )
          }
          return (
            <Item key={url}>
              <CustomLink to={url}>{title}</CustomLink>
              {title === 'Wie we zoeken' && <Amount>{count}</Amount>}
            </Item>
          )
        }
      )}
    </>
  )
}

export default Header
