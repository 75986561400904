import React from 'react'

// Third Party
import { motion } from 'framer-motion'
import handleViewport from 'react-in-viewport'

const AnimatedFingerprint = (props: {
  inViewport: boolean
  forwardedRef: any
}) => {
  const { inViewport, forwardedRef } = props

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="855.006"
      height="855.006"
      viewBox="0 0 855.006 855.006"
      className="viewport-block"
      ref={forwardedRef}
    >
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
            },
          }
        }
        stroke="white"
        d="M131.774,9.963a12.4,12.4,0,1,0-24.315,4.9c28.291,140.386-9.753,282.7-104.373,390.467a12.4,12.4,0,1,0,18.633,16.377C121.492,308.085,161.606,158.007,131.774,9.963"
        transform="translate(307.943 415.245)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.05,
            },
          }
        }
        stroke="white"
        d="M106.884.854A12.4,12.4,0,0,0,90.822,7.9a402.947,402.947,0,0,1-87.3,135.531A12.4,12.4,0,1,0,21.28,160.762,427.567,427.567,0,0,0,113.929,16.925,12.414,12.414,0,0,0,106.884.854"
        transform="translate(254.312 658.286)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.1,
            },
          }
        }
        stroke="white"
        d="M129.722,62.788l.27-.088L127.8,51.82A64.534,64.534,0,1,0,1.281,77.351L3.645,89.094l.2-.035A399.794,399.794,0,0,1,.8,241.2a12.4,12.4,0,1,0,24.25,5.2,425.013,425.013,0,0,0,.912-174.018L24.88,67.036A39.731,39.731,0,0,1,102.6,53.168l.729,3.61A506.727,506.727,0,0,1,5.736,469.232,12.4,12.4,0,1,0,25.28,484.513,531.522,531.522,0,0,0,129.722,62.788"
        transform="translate(362.916 363.081)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.15,
            },
          }
        }
        stroke="white"
        d="M136.811,10.744a12.4,12.4,0,1,0-24.579,3.333C127.446,126.4,86.958,239.862,3.93,317.589a12.406,12.406,0,1,0,16.946,18.123C109.762,252.5,153.1,131.02,136.811,10.744"
        transform="translate(205.298 459.141)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.2,
            },
          }
        }
        stroke="white"
        d="M228.248,93.452A116.353,116.353,0,0,0,.264,92.709a12.4,12.4,0,1,0,24.28,5.069,91.554,91.554,0,0,1,178.317-4.127l.957,4.749a563.253,563.253,0,0,1,11.121,109.416,12.4,12.4,0,0,0,12.4,12.376h.032a12.4,12.4,0,0,0,12.369-12.442,587.543,587.543,0,0,0-9.585-103.711l.211-.077Z"
        transform="translate(313.261 311.203)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.25,
            },
          }
        }
        stroke="white"
        d="M110.061.08A12.411,12.411,0,0,0,96.332,11,561.024,561.024,0,0,1,2.168,263.195a12.4,12.4,0,1,0,20.47,14.015A586.014,586.014,0,0,0,120.98,13.817,12.412,12.412,0,0,0,110.061.08"
        transform="translate(428.3 572.396)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.3,
            },
          }
        }
        stroke="white"
        d="M126.184,196.605l-1.406-6.21C113.008,115.077,162.7,42.89,238.1,27.674a143.787,143.787,0,0,1,39.557-2.427A12.409,12.409,0,0,0,279.582.5,168.431,168.431,0,0,0,233.2,3.346C142.3,21.689,83.255,110.607,101.583,201.564l1.737,7.655a300.164,300.164,0,0,1-99.037,277.9A12.4,12.4,0,1,0,20.52,505.875a324.984,324.984,0,0,0,105.664-309.27"
        transform="translate(161.017 259.369)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.35,
            },
          }
        }
        stroke="white"
        d="M103.3,125.207l.146-.063-1.978-9.811A168.14,168.14,0,0,0,18.442,1.573,12.407,12.407,0,0,0,6.366,23.25a143.369,143.369,0,0,1,69.779,92.367l.937,4.654A609.863,609.863,0,0,1,5.319,549.726a12.4,12.4,0,1,0,21.393,12.553A634.558,634.558,0,0,0,103.3,125.207"
        transform="translate(490.835 279.076)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.4,
            },
          }
        }
        stroke="white"
        d="M101.766,16.9A12.4,12.4,0,1,0,78.647,7.918,248.928,248.928,0,0,1,4.542,110.184a12.407,12.407,0,1,0,15.719,19.2A273.818,273.818,0,0,0,101.766,16.9"
        transform="translate(121.632 601.376)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.45,
            },
          }
        }
        stroke="white"
        d="M440.24,424a684.262,684.262,0,0,0-3.1-239.631l.067-.053-1.546-7.678C411.682,57.658,295.434-19.618,176.533,4.364S-19.612,144.675,4.365,263.659l1.566,7.046a246.9,246.9,0,0,1,2.212,68.148,12.4,12.4,0,1,0,24.664,2.634A272.211,272.211,0,0,0,28.921,258.7l-1.454-6.541C10.275,148.922,78.129,49.538,181.436,28.692,285.719,7.653,387.719,74.425,410.589,177.943l.729,3.62a659.979,659.979,0,0,1,4.447,238.4,12.414,12.414,0,0,0,10.222,14.264,12.675,12.675,0,0,0,2.032.166A12.411,12.411,0,0,0,440.24,424"
        transform="translate(207.441 207.527)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.5,
            },
          }
        }
        stroke="white"
        d="M60.82.426A12.4,12.4,0,0,0,45.634,9.207,664.458,664.458,0,0,1,1.256,128.035a12.4,12.4,0,1,0,22.3,10.87A689.417,689.417,0,0,0,69.6,15.623,12.41,12.41,0,0,0,60.82.426"
        transform="translate(563.888 681.708)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.55,
            },
          }
        }
        stroke="white"
        d="M68.16,107l-.045-.036A269.72,269.72,0,0,0,22.429,5.1,12.405,12.405,0,1,0,2.379,19.714a245.269,245.269,0,0,1,42.357,96.608l.537,2.615a712.6,712.6,0,0,1-33.26,393.2,12.4,12.4,0,1,0,23.168,8.864A737.381,737.381,0,0,0,69.065,111.427Z"
        transform="translate(624.844 262.503)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.6,
            },
          }
        }
        stroke="white"
        d="M66.981,5.416a273.014,273.014,0,0,0-59.745,19.4A12.409,12.409,0,0,0,17.571,47.381,247.337,247.337,0,0,1,277.327,80.9a12.4,12.4,0,1,0,15.712-19.2A270.824,270.824,0,0,0,66.981,5.416"
        transform="translate(306.751 155.651)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.65,
            },
          }
        }
        stroke="white"
        d="M98.264,262.885l-1.455-6.666A246.712,246.712,0,0,1,184.618,22.044,12.407,12.407,0,0,0,168.98,2.779,271.5,271.5,0,0,0,73.752,267.835l1.352,6.192A194.993,194.993,0,0,1,4.634,458.943,12.406,12.406,0,1,0,20.17,478.29a219.75,219.75,0,0,0,78.094-215.4"
        transform="translate(87.258 213.599)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.7,
            },
          }
        }
        stroke="white"
        d="M76.713,59.148l-1.44-6.71a302.226,302.226,0,0,1-4.425-40.53,12.4,12.4,0,1,0-24.781,1.011A327.277,327.277,0,0,0,52.24,64.092l1.109,5.16A143.822,143.822,0,0,1,4.384,202.105a12.406,12.406,0,1,0,16.034,18.934,168.635,168.635,0,0,0,56.3-161.891"
        transform="translate(57.971 427.594)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.75,
            },
          }
        }
        stroke="white"
        d="M612.044,264.757l1.169,5.635c2.2,11.334,4.153,22.761,5.825,34.05a12.4,12.4,0,1,0,24.538-3.643c-2.014-13.579-4.424-27.354-7.16-40.943l-1.909-9.184-.14-.013C595.055,80.869,426.991-28.251,255.159,6.425A323.828,323.828,0,0,0,.185,268.457a12.4,12.4,0,1,0,24.436,4.25A299.026,299.026,0,0,1,260.061,30.752c161.519-32.588,319.418,72.392,351.983,234"
        transform="translate(108.33 103.819)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.8,
            },
          }
        }
        stroke="white"
        d="M36.212.02a12.409,12.409,0,0,0-11.6,13.168A760.958,760.958,0,0,1,.422,257.483a12.4,12.4,0,1,0,23.967,6.391A785.89,785.89,0,0,0,49.372,11.624,12.449,12.449,0,0,0,36.212.02"
        transform="translate(709.539 459.411)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.85,
            },
          }
        }
        stroke="white"
        d="M50.331,445.06l-1.358-6.431A350.954,350.954,0,0,1,440.917,27.955a12.408,12.408,0,0,0,3.318-24.593A375.695,375.695,0,0,0,25.9,450l.811,3.844A92.085,92.085,0,0,1,3.308,530.5,12.406,12.406,0,1,0,21.5,547.375,116.945,116.945,0,0,0,50.331,445.06"
        transform="translate(33.515 51.936)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.9,
            },
          }
        }
        stroke="white"
        d="M267.768,273.674l-.158.008A376.22,376.22,0,0,0,16.189.6,12.408,12.408,0,0,0,8.62,24.23,351.38,351.38,0,0,1,245.582,289.147l1.524,7.209a817.247,817.247,0,0,1,8.627,255.723,12.41,12.41,0,0,0,10.774,13.849,13.078,13.078,0,0,0,1.548.094,12.4,12.4,0,0,0,12.293-10.877A842.418,842.418,0,0,0,270,284.236Z"
        transform="translate(525.586 69.184)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 0.95,
            },
          }
        }
        stroke="white"
        d="M5.093 192.9a12.39 12.39 0 0 0 17.327-2.709A401.339 401.339 0 0 1 267.9 32.925c87.746-17.707 176.484-6.255 256.62 33.1a12.409 12.409 0 0 0 10.93-22.281C450.365 1.961 356.156-10.2 263 8.6A426.072 426.072 0 0 0 2.384 175.564 12.413 12.413 0 0 0 5.093 192.9"
        transform="translate(80.004 0)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 1,
            },
          }
        }
        stroke="white"
        d="M193.091,267.622a12.472,12.472,0,0,0-.753-2.429A425.646,425.646,0,0,0,19.528,2.252,12.407,12.407,0,0,0,5.278,22.567a400.988,400.988,0,0,1,163.276,250,12.434,12.434,0,0,0,.632,2.133,405.783,405.783,0,0,1,7.537,85.583,12.406,12.406,0,0,0,12.162,12.643c.079,0,.16,0,.243,0a12.405,12.405,0,0,0,12.392-12.173,430.581,430.581,0,0,0-8.429-93.135"
        transform="translate(653.409 75.515)"
      />
      <motion.path
        initial={{
          opacity: 0,
          pathLength: 0,
          fill: 'rgba(255,255,255,0)',
        }}
        animate={
          inViewport && {
            opacity: 1,
            pathLength: 1,
            fill: 'rgba(255,255,255,1)',
            transition: {
              duration: 0.6,
              delay: 1.05,
            },
          }
        }
        stroke="white"
        d="M37.006,298.132c-1.394-5.64-2.688-11.423-3.852-17.185a12.49,12.49,0,0,0-.635-2.114C14.962,189.292,27.58,99.173,69.089,18.069A12.4,12.4,0,1,0,47.013,6.757c-44.426,86.8-57.7,183.326-38.4,279.141a12.313,12.313,0,0,0,.729,2.325c1.1,5.324,2.293,10.651,3.583,15.866a12.4,12.4,0,1,0,24.077-5.957"
        transform="translate(0 226.291)"
      />
    </motion.svg>
  )
}

const ViewportBlock = handleViewport(AnimatedFingerprint)

const ViewPortAnimatedFingerprint = () => <ViewportBlock />

export default ViewPortAnimatedFingerprint
