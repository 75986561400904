import React from 'react'

export default function Triangle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="19"
      fill="none"
      viewBox="0 0 13 19"
    >
      <path
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1v17l11-8.5L1 1z"
        clipRule="evenodd"
      />
    </svg>
  )
}
