import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.light};
  color: ${(props) => props.theme.color.alternate};
`

const Image = styled(Plaatjie)`
  width: 120px;
`

const Usp = styled.h2`
  font-size: ${(props) => props.theme.font.size.mega};
  font-weight: bold;
  background-color: #ca4246;
  background-image: linear-gradient(
    135deg,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 15%
  );
  background-size: 100%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 25px;
    padding-bottom: 20px;
  }

  & a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    margin-bottom: 6px;
  }

  & > span,
  & > p > span {
    margin-top: 20px;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          usp {
            title
          }
          columnLeft {
            description
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, width: 1280)
                }
              }
            }
          }
          columnCenter {
            description
          }
          columnRight {
            description
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledFooter>
        <div className="container py-5">
          <Usp>{fields?.footer?.usp?.title}</Usp>
          <div className="row">
            <div className="col-lg-5 pr-lg-5">
              <Content
                className="mt-5 mb-3"
                content={fields?.footer?.columnLeft?.description}
              />
              <Image image={fields?.footer?.columnLeft?.image} alt="" />
            </div>
            <div className="col-sm-5 col-lg-3 mt-3 mt-lg-0">
              <Content
                className="mt-lg-5"
                content={fields?.footer?.columnCenter?.description}
              />
            </div>
            <div className="col-sm-7 col-lg-4 mt-3 mt-lg-0">
              <Content
                className="mt-lg-5"
                content={fields?.footer?.columnRight?.description}
              />
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

export default Footer
