import React from 'react'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const StyledButton = styled(motion.span)`
  ${(props) => css`
    background: rgb(${props.theme.color.gradient.contrast.to});
  `}
  display: inline-flex;
  border-radius: 25px;
  transition: all 0.3s ease;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    text-decoration: none !important;

    &:hover {
      color: ${(props) => props.theme.color.light};
    }
    @media (min-width: 992px) {
      padding: 10px 60px 10px 35px;
      font-size: ${(props) => props.theme.font.size.medium};
      line-height: ${(props) => props.theme.font.size.large};
      white-space: nowrap;
    }
    @media (max-width: 991px) {
      padding: 10px 45px 10px 20px;
      font-size: ${(props) => props.theme.font.size[12]};
      line-height: ${(props) => props.theme.font.size.medium};
    }
  }
`

interface ButtonDefaultArrowProps {
  isAnchor?: boolean
  isCustom?: boolean
  hasArrow?: boolean
  to: string
  children: any
  className?: string
}

const ButtonDefaultArrow: React.FC<ButtonDefaultArrowProps> = ({
  isAnchor = false,
  isCustom = false,
  // hasArrow = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton
    initial={{
      background:
        'linear-gradient(90deg,rgba(241,37,48, 1) 0%,rgba(255,132,0, 1) 50%)',
    }}
    whileHover={{
      background:
        'linear-gradient(90deg,rgba(241,37,48, 1) 20%,rgba(255,132,0, 1) 80%)',
    }}
    transition={{ type: 'spring' }}
    className={className}
  >
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
        <Arrow />
      </ButtonShell>
    )}
  </StyledButton>
)

const StyledArrow = styled.span`
  background-color: ${(props) => props.theme.color.light};
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 16px;
    height: 16px;
  }
  @media (min-width: 992px) {
    top: 5px;
    right: 5px;
    height: 34px;
    width: 34px;
  }
  @media (max-width: 991px) {
    top: 3px;
    right: 3px;
    height: 30px;
    width: 30px;
  }
`

export const Arrow = () => (
  <StyledArrow>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="19"
      viewBox="0 0 13 19"
    >
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0V17L11,8.5Z"
        transform="translate(1 1)"
        fill="none"
        stroke="#ff8300"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </StyledArrow>
)

export default ButtonDefaultArrow
