import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

const StyledButton = styled.span`
  ${(props) => `
    background: rgb(${props.theme.color.gradient.contrast.to});
    background: linear-gradient(90deg, rgba(${props.theme.color.gradient.contrast.from},1) 0%, rgba(${props.theme.color.gradient.contrast.to},1) 50%);
  `}
  display: inline-flex;
  border-radius: 25px;
  align-items: center;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light};
    padding: 1rem;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    text-decoration: none !important;

    &:hover {
      color: ${(props) => props.theme.color.light};
    }
    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.medium};
      line-height: ${(props) => props.theme.font.size.large};
      white-space: nowrap;
    }
    @media (max-width: 991px) {
      padding: 10px 45px 10px 20px;
      font-size: ${(props) => props.theme.font.size.small};
      line-height: ${(props) => props.theme.font.size.medium};
    }
  }
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  hasArrow?: boolean
  to: string
  children: any
  className?: string
}

const ButtonDefault: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  isCustom = false,
  hasArrow = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
        {hasArrow && <Arrow />}
      </ButtonShell>
    )}
  </StyledButton>
)

const StyledArrow = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 16px;
    height: 16px;
  }
`

export const Arrow = () => (
  <StyledArrow>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="19"
      viewBox="0 0 13 19"
    >
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0V17L11,8.5Z"
        transform="translate(1 1)"
        fill="none"
        stroke="#ff8300"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </StyledArrow>
)

export default ButtonDefault
