import React from 'react'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Third Party
import styled from 'styled-components'
// eslint-disable-next-line import/extensions
import Triangle from '../SvgElements/Triangle'

const StyledButton = styled.span`
  position: relative;
  display: inline-flex;

  & > a,
  & > button {
    background: ${(props) => props.theme.color.light};

    color: ${(props) => props.theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    width: 100%;
    height: 100%;
    padding: 1rem;
    align-items: center;
    display: inline-flex;
    position: relative;
    text-decoration: none !important;
    transition: all 0.3s ease;
    border-radius: 25px;

    &:hover {
      color: ${(props) => props.theme.color.secondary};
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    }
    @media (min-width: 992px) {
      padding: 10px 60px 10px 35px;
      font-size: ${(props) => props.theme.font.size.medium};
      line-height: ${(props) => props.theme.font.size.large};
      white-space: nowrap;
    }
    @media (max-width: 991px) {
      padding: 10px 45px 10px 20px;
      font-size: ${(props) => props.theme.font.size.small};
      line-height: ${(props) => props.theme.font.size.medium};
    }
  }
`

const TriangleWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(241, 37, 48, 1) 0%,
    rgba(255, 132, 0, 1) 50%
  );
  border-radius: 100%;

  z-index: 50;
  pointer-events: none;

  @media (min-width: 992px) {
    width: 37px;
    height: 37px;
    top: 3px;
    right: 4px;
  }

  @media (max-width: 991.98px) {
    width: 28px;
    height: 28px;
    top: 4px;
    right: 5px;
  }
`

interface ButtonWhiteArrowProps {
  isAnchor?: boolean
  isCustom?: boolean
  hasArrow?: boolean
  to: string
  children: any
  className?: string
}

const ButtonWhiteArrow: React.FC<ButtonWhiteArrowProps> = ({
  isAnchor = false,
  isCustom = false,
  hasArrow = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      <>
        <>{children}</>
        {hasArrow && (
          <TriangleWrapper>
            <Triangle />
          </TriangleWrapper>
        )}
      </>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
        <Arrow />
      </ButtonShell>
    )}
  </StyledButton>
)

const StyledArrow = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 16px;
    height: 16px;
  }
  @media (min-width: 992px) {
    top: 5px;
    right: 5px;
    height: 34px;
    width: 34px;
  }
  @media (max-width: 991px) {
    top: 3px;
    right: 3px;
    height: 30px;
    width: 30px;
  }
`

export const Arrow = () => (
  <StyledArrow>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="19"
      viewBox="0 0 13 19"
    >
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0V17L11,8.5Z"
        transform="translate(1 1)"
        fill="none"
        stroke="#ff8300"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </StyledArrow>
)

export default ButtonWhiteArrow
